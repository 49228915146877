<script>
import { Button, Col, Row, Divider } from 'ant-design-vue'
import FormView from '../FormView'
export default {
  props: ['data', 'onSuccess', 'typeData'],
  data() {
    return {
      isMore: false,
      layout: 'inline',
      form: {},
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 900) {
        if (this.layout !== 'vertical') {
          this.layout = 'vertical'
        }
      } else {
        if (this.layout != 'inline') {
          this.layout = 'inline'
        }
      }
    })
  },
  methods: {
    onSearch() {
      this.$refs._form.sumbit()
    },
    onReset() {
      this.$refs._form.reset()
      this.$nextTick(() => {
        this.$emit('reset')
      })
    },
    renderMore() {
      return (
        <Col span={8}>
          <a onClick={() => (this.isMore = !this.isMore)} class="header-more">
            {!this.isMore ? '展开' : '收起'}
            <a-icon type={this.isMore ? 'up' : 'down'} style={{ marginLeft: '6px', fontSize: '10px' }} />
          </a>
        </Col>
      )
    },
    getCol() {
      if (this.data.length == 1) {
        return {
          span: 24 - 8,
        }
      }
      if (this.data.length * 6 >= 24) {
        return {
          span: 24,
        }
      } else {
        return {
          span: 24 - this.data.length * 6,
        }
      }
    },
    renderRight() {
      const data = [
        {
          name: '查询',
          type: 'default',
          onClick: this.onSearch,
        },
        {
          name: '重置',
          type: 'primary',
          onClick: this.onReset,
        },
      ]
      return (
        <Col
          props={this.getCol()}
          class="search-button"
          style={{
            marginTop: this.isMore ? '10px' : '0px',
            marginBottom: '10px',
          }}
        >
          <Row gutter={20}>
            {data.map((e) => {
              return (
                <Col span={this.data.length > 4 ? 8 : 12}>
                  <Button type={e.type} onClick={e.onClick} style={{ borderRadius: '4px' }}>
                    {e.name}
                  </Button>
                </Col>
              )
            })}
            {this.data.length > 4 && this.renderMore()}
          </Row>
        </Col>
      )
    },
    getFormData(data) {
      return data.map((e) => {
        return e
      })
    },
    submit({ isPromise = true } = {}) {
      return new Promise((resolve, reject) => {
        this.$refs._form.sumbit({ isPromise }).then((e) => {
          resolve({ ...this.form, ...e })
        })
      })
    },
  },
  render() {
    const data = this.isMore ? this.getFormData(this.data) : this.getFormData(this.data.slice(0, 4))
    return (
      <div style={{ marginTop: '-17px', marginLeft: '-60px' }}>
        <FormView
          data={data}
          ref={'_form'}
          labelCol={this.layout == 'inline' ? { span: 6 } : {}}
          wrapperCol={this.layout == 'inline' ? { span: 18 } : {}}
          layout={this.layout}
          style={{ width: '100%' }}
          typeData={this.typeData}
          onSuccess={(data) => this.$emit('success', data)}
          onSelectChange={this.onSearch}
          cols={this.data.length == 1 ? 8 : 6}
          form={this.form}
        >
          {this.renderRight()}
        </FormView>
        <Divider style={{ margin: 'auto' }} />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.search-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-left: -8px;
}
</style>
